<template>
        <apexchart height="150px" type='line' :options="datos.options" :series="datos.series"/>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

    components: {
        'apexchart':VueApexCharts,
    },
    data:()=>({
    }),

    methods:{
    },
}
</script>

<style>

</style>